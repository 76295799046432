import { Card } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/RemoveCircleOutline';
import * as React from 'react';
import { useCallback, useState, useRef, useEffect } from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Button,
  Create,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import { DestinationListInput, TrafficInstructionInput } from '../../components';
import { alphanumeric } from '../../helpers';
import { createUpdateStyle } from '../../style';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  BRSCustomToolbar,
  EstimateIdInput,
  IsciInput,
  OriginalIsciInput,
  SlateTypeInput,
} from '../../components/BrsFormComponents';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

export const BrsCreate = (props) => {
  const classes = useStyles();
  const datePickerRef = useRef(null);

  const [validatingIsci, setValidatingIsci] = useState(true);
  const [validatingOriginalIsci, setValidatingOriginalIsci] = useState(true);
  const [originalIscisDisabled, setOriginalIscisDisabled] = useState({});
  const [unitRotation, setUnitRotation] = useState('unit');
  const [includeSD, setIncludeSD] = useState({});
  const [focusedInputs, setFocusedInputs] = useState({});
  const [disabled, setDisabled] = useState(false);

  const validateOriginalIscis = (data) => {
    if (data?.isciasset?.original_iscis?.length === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  const handleIncludeSDChange = useCallback(
    (field) => async (value) => {
      setIncludeSD({ ...includeSD, [field]: value });
    },
    [includeSD],
  );

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape' && Object.values(focusedInputs).some((input) => input !== null)) {
        event.preventDefault();
        event.stopPropagation();
        setFocusedInputs({});
      }
    };
    document.addEventListener('keydown', handleEsc, true);
    return () => {
      document.removeEventListener('keydown', handleEsc, true);
    };
  }, [focusedInputs]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (Object.values(focusedInputs).some((input) => input !== null)) {
        const portal = document.getElementById('daterangepicker-portal');
        if (!portal || !portal.contains(event.target)) {
          const isCalendarClick =
            event.target.closest('.DayPicker') ||
            event.target.closest('.CalendarDay') ||
            event.target.closest('.DayPickerNavigation') ||
            event.target.closest('.CalendarMonth') ||
            event.target.closest('.DateRangePicker');

          if (!isCalendarClick) {
            setFocusedInputs({});
          }
        }
      }
    };

    if (Object.values(focusedInputs).some((input) => input !== null)) {
      document.addEventListener('mousedown', handleClickOutside, true);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside, true);
      };
    }
  }, [focusedInputs]);

  return (
    <div className={classes.root}>
      <a className={classes.breadcrumbLink} href="#/brs">
        {'< BRS Jobs'}
      </a>
      <Create
        title="Add BRS Job"
        resource="brsjob"
        basePath={'/brs'}
        {...props}
        style={{ overflow: 'visible', position: 'static' }}>
        <SimpleForm
          redirect="list"
          toolbar={<BRSCustomToolbar {...props} tiUpdate={false} saveButtonExtraProps={{ disabled }} />}
          validateOnBlur={true}>
          <Typography variant="h6">New Request</Typography>
          <Card style={{ display: 'flex', width: '100%' }}>
            <TextInput
              validate={[required()]}
              className={classes.textField}
              source="request_id"
              label="Request ID"
              style={{ marginRight: '2rem', width: '25%' }}
            />
            <EstimateIdInput
              validate={[required()]}
              className={classes.textField}
              source="estimate_id"
              label="Estimate ID"
              initialEstimateId=""
              style={{ marginRight: '2rem', width: '25%' }}
            />
            <SlateTypeInput
              source="slate_type"
              validate={[required()]}
              choices={[
                { id: 'CADENT', name: 'Cadent' },
                { id: 'CROSS', name: 'Cross' },
                {
                  id: 'FLUIDITY',
                  name: 'Fluidity',
                },
              ]}
              className={classes.textField}
              defaultValue="CADENT"
              style={{ marginRight: '2rem', width: '25%' }}
            />
            <SelectInput
              source="market_type"
              validate={[required()]}
              choices={[
                { id: 'LOCAL', name: 'Local' },
                { id: 'NATIONAL', name: 'National' },
              ]}
              className={classes.textField}
              defaultValue="LOCAL"
              style={{
                marginRight: '2rem',
                width: '25%',
              }}
            />
          </Card>
          <Card style={{ display: 'inline-flex', width: '100%' }}>
            <div style={{ marginRight: '2rem', width: '23%' }}>
              <ReferenceInput
                source="inventory_source_id"
                label="Inventory Source"
                perPage={500}
                sort={{ field: 'name', order: 'ASC' }}
                className={classes.textField}
                validate={[required()]}
                reference="inventorysource"
                filterToQuery={(searchText) => ({ name: searchText })}
                //style={{ marginRight: '2rem', width: '100%' }}
              >
                <AutocompleteInput optionText="name" />
              </ReferenceInput>
            </div>
            <div style={{ marginRight: '2rem', width: '23%' }}>
              <SelectInput
                source="unit_rotation"
                choices={[
                  { id: 'unit', name: 'Unit' },
                  { id: 'rotation', name: 'Rotation' },
                ]}
                onChange={(e) => setUnitRotation(e.target.value)}
                defaultValue="unit"
                label="Unit/Rotation"
                className={classes.textField}
                validate={[required()]}
                //style={{ marginRight: '2rem', width: '23%' }}
              />
            </div>
          </Card>
          <Card style={{ width: '98.6%', padding: 10 }} variant="outlined">
            <ArrayInput source="brs_spots" initialValue={[{}]} label="" validate={[required()]}>
              <SimpleFormIterator
                TransitionProps={{ enter: false, exit: false }}
                variant="filled"
                addButton={
                  <Button label="ra.action.add" style={{ color: '#3CE7EA' }}>
                    <AddIcon />
                  </Button>
                }
                removeButton={
                  <Button label="ra.action.remove" style={{ color: '#e57373', marginTop: 12, marginRight: 8 }}>
                    <CloseIcon />
                  </Button>
                }>
                <FormDataConsumer>
                  {({ getSource, scopedFormData, index }) => {
                    if (unitRotation === 'rotation' && scopedFormData?.unit) {
                      scopedFormData.unit = '';
                    } else if (unitRotation === 'unit' && scopedFormData?.rotation) {
                      scopedFormData.rotation = '';
                    }
                    return (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '8px',
                          }}>
                          <div className={classes.dateRangePickerContainer} ref={datePickerRef}>
                            <DateRangePicker
                              startDate={scopedFormData?.start_date ? moment(scopedFormData.start_date) : null}
                              startDateId={`start_date_${index}`}
                              endDate={scopedFormData?.end_date ? moment(scopedFormData.end_date) : null}
                              endDateId={`end_date_${index}`}
                              onDatesChange={({ startDate, endDate }) => {
                                scopedFormData.start_date = startDate ? startDate.format('YYYY-MM-DD') : null;
                                scopedFormData.end_date = endDate ? endDate.format('YYYY-MM-DD') : null;
                              }}
                              focusedInput={focusedInputs[index]}
                              onFocusChange={(focusedInput) => {
                                setFocusedInputs((prev) => ({ ...prev, [index]: focusedInput }));
                              }}
                              displayFormat="YYYY-MM-DD"
                              isOutsideRange={() => false}
                              appendToBody={true}
                              openDirection="down"
                              anchorDirection="left"
                              withPortal={true}
                              portalId="daterangepicker-portal"
                              keepOpenOnDateSelect={false}
                              minimumNights={0}
                              onClose={() => setFocusedInputs((prev) => ({ ...prev, [index]: null }))}
                              closeOnEscape={true}
                              noBorder={true}
                            />
                          </div>
                          <TextInput
                            source={getSource('title')}
                            record={scopedFormData}
                            label="Title"
                            style={{ marginLeft: '0', width: '20%' }}
                            disabled={false}
                          />
                        </div>
                        <div style={{ display: 'flex', width: '100%' }}>
                          <IsciInput
                            className={classes.textField}
                            validate={[required(), alphanumeric]}
                            source={getSource('isciasset.isci')}
                            record={scopedFormData}
                            label="ISCI"
                            style={{ marginRight: '2rem', width: '25%' }}
                            setValidatingIsci={setValidatingIsci}
                            setOriginalIscisDisabled={setOriginalIscisDisabled}
                            originalIscisDisabled={originalIscisDisabled}
                            originalIscisField={getSource('isciasset.isci')}
                            duration={getSource('isciasset.duration')}
                            originaliscis={getSource('isciasset.original_iscis')}
                            isciAssetSource={getSource('isciasset')}
                          />
                          <SelectInput
                            source={getSource('isciasset.sigma_encoding')}
                            choices={[
                              { id: 'NONE', name: 'None' },
                              { id: 'CADENT', name: 'Cadent' },
                              {
                                id: 'TTWN',
                                name: 'TTWN',
                              },
                            ]}
                            defaultValue="CADENT"
                            label="Sigma Encoding"
                            className={classes.textField}
                            validate={[required()]}
                            style={{ marginRight: '2rem', width: '25%' }}
                            disabled={scopedFormData && scopedFormData['isciasset']?.exists}
                          />
                          <SelectInput
                            choices={[{ id: 'NO', name: 'No' }]}
                            defaultValue="NO"
                            label="BVS Encoding"
                            className={classes.textField}
                            validate={[required()]}
                            source={getSource('isciasset.bvs_encoding')}
                            style={{ marginRight: '2rem', width: '25%' }}
                            disabled={true}
                          />
                          <NumberInput
                            className={classes.textField}
                            style={{ display: 'none' }}
                            source={getSource('isciasset.duration')}
                            record={scopedFormData}
                            helperText={false}
                            initialValue={0}
                          />
                          <TextInput
                            className={classes.textField}
                            validate={[required()]}
                            source={getSource(unitRotation)}
                            record={scopedFormData}
                            label={unitRotation}
                            style={{ width: '12%', minWidth: '60px' }}
                            onChange={(e) => {
                              scopedFormData[unitRotation] = e.target.value;
                            }}
                            InputProps={{
                              endAdornment:
                                unitRotation === 'rotation' ? (
                                  <InputAdornment position="start">%</InputAdornment>
                                ) : null,
                            }}
                          />
                          <BooleanInput
                            defaultValue={true}
                            className={classes.switch}
                            source={getSource('isciasset.include_sd')}
                            record={scopedFormData}
                            validate={[required()]}
                            onChange={handleIncludeSDChange(getSource('include_sd'))}
                            label="Include SD"
                            style={{
                              margin: '1rem 2rem 0rem 2rem',
                              width: '25%',
                            }}
                            disabled={scopedFormData && scopedFormData['isciasset']?.exists}
                          />
                        </div>
                        <div style={{ display: 'flex', width: '96%' }}>
                          <OriginalIsciInput
                            source={getSource('isciasset.original_iscis')}
                            //validate={[validateOriginalIscis(scopedFormData)]}
                            nestedLabel="Original ISCI"
                            maxLength={4}
                            disabled={
                              originalIscisDisabled.hasOwnProperty(getSource('isciasset.isci'))
                                ? originalIscisDisabled[getSource('isciasset.isci')]
                                : true
                            }
                            setValidatingOriginalIsci={setValidatingOriginalIsci}
                            getSource={getSource}
                            durationSource={getSource('isciasset.duration')}
                            isciAssetExists={scopedFormData && scopedFormData['isciasset']?.exists}
                          />
                        </div>
                      </>
                    );
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </Card>
          <Card style={{ display: 'flex', width: '100%', margin: '10px 0' }}>
            <DestinationListInput
              brsDestinations={[]}
              style={{ width: '50%' }}
              source="destinations"
              resource="destination"
              sort={{ field: 'call_letters', order: 'ASC' }}
              canDelete={true}
              isCreate={true}
            />
            <TrafficInstructionInput source="trafficinstructions" style={{ width: '50%' }} />
          </Card>
        </SimpleForm>
      </Create>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  dateRangePickerContainer: {
    width: '740px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '20px',
    marginRight: '1rem',
    '& .DateRangePicker': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
    },
    '& .DateRangePickerInput': {
      display: 'flex',
      flexDirection: 'row',
      border: 'unset',
      gap: '18px',
      backgroundColor: '#424242',
      width: '100%',
    },
    '& .DateInput': {
      width: '340px',
      borderBottom: '1px solid #ffffff',
      padding: '0 0 0 3px',
      margin: '0 15px 0 0',
      backgroundColor: '#5A5A5A',
      height: '48px',
      display: 'flex',
      '&:hover': {
        backgroundColor: '#6B6B6B',
        borderColor: '#aaa',
      },
      '& .DateInput_input': {
        color: 'rgba(255, 255, 255, 0.7)',
        fontSize: '0.875rem',
        '&:focus': {
          color: 'rgba(255, 255, 255, 0.7)',
        },
        '&:not(:placeholder-shown)': {
          color: '#ffffff',
        },
      },
    },
    '& .DateInput_input': {
      padding: '0',
      backgroundColor: '#5A5A5A',
      fontSize: '0.875rem',
      '&:hover': {
        backgroundColor: '#6B6B6B',
        borderColor: '#aaa',
      },
    },
    '& .DateInput_input::placeholder': {
      color: 'rgba(255, 255, 255, 0.7)',
      fontSize: '0.875rem',
    },
    '& .DateRangePickerInput_arrow': {
      display: 'none !important',
    },
  },

  textField: {
    width: '98%',
  },
  '& svg': {
    color: 'red',
  },
  link: {
    color: '#3CE7EA',
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontWeight: 400,
    textDecoration: 'underline',
  },
  breadcrumbLink: {
    color: '#3CE7EA',
    fontSize: '16px',
    fontFamily: 'sans-serif, Roboto',
    fontWeight: 400,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  root: {
    width: '100%',
    marginTop: '24px',
    position: 'relative',
    height: 'calc(100vh - 100px)', // Adjust height to account for top margin
    overflowY: 'auto',
    '& .ra-input': {
      position: 'static',
    },
    '& .RaSimpleFormIterator-form': {
      position: 'static',
      overflow: 'auto',
      maxHeight: '500px',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#424242',
        borderRadius: '4px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#5A5A5A',
        borderRadius: '4px',
        '&:hover': {
          background: '#6B6B6B',
        },
      },
    },
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#424242',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#5A5A5A',
      borderRadius: '4px',
      '&:hover': {
        background: '#6B6B6B',
      },
    },
  },
  card: {
    width: '100%',
    marginTop: '24px',
    position: 'relative',
    '& .ra-input': {
      position: 'static',
    },
    '& .RaSimpleFormIterator-form': {
      position: 'static',
      overflow: 'auto',
      maxHeight: '500px',
    },
  },
  switch: {
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#3CE7EA',
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#3CE7EA',
    },
  },
  statusButton: {
    borderRadius: '50%',
    backgroundColor: 'green',
    color: 'white',
    minWidth: '26px',
    padding: 0,
    height: '26px',
    '&:hover': {
      backgroundColor: 'darkgreen',
    },
  },
  modalToolbar: {
    backgroundColor: 'initial',
    justifyContent: 'end',
  },
  showButton: {
    color: '#3CE7EA',
    border: '1px solid #3CE7EA',
  },
  button: {
    backgroundColor: '#3CE7EA',
    color: '#2A2A2A',
    borderRadius: 'unset',
    padding: '4px 8px 4px 8px',
    textTransform: 'none',
    fontSize: '14px',
    opacity: 0.9,
    '&:hover': {
      backgroundColor: '#3CE7EA',
      color: '#2A2A2A',
      opacity: 1,
      boxShadow: 'none',
    },
  },
}));
